import React from "react";
import VoiceToText from "voice2text";

const useVoice2Text = () => {
  const [isListening, setIsListening] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFinal, setIsFinal] = React.useState(false);
  const [text, setText] = React.useState("");

  const voice2Text = new VoiceToText({
    converter: "vosk",
    language: "en",
    sampleRate: 16000, //Can remove. Need to be tested further
  });

  const startVoice = () => {
    if (!isListening) {
      voice2Text.start();
      setIsLoading(true);
      setIsListening(true);
      setText("");
      window.addEventListener("voice", captureVoiceAndStatus);
    }
  };

  const stopVoice = () => {
    if (isListening) {
      window.removeEventListener("voice", captureVoiceAndStatus, false);
      window.removeEventListener("voice", captureVoiceAndStatus, true);
      voice2Text.stop();
      setIsLoading(true);
      setIsListening(false);
      setText("");
    }
  };

  const pauseVoice = () => {
    if (isListening) {
      voice2Text.pause();
      setIsListening(false);
    }
  };

  const resetText = () => {
    setIsFinal(false);
    setText("");
  };

  const captureVoiceAndStatus = (e: any) => {
    let tempText = "";
    if (e.detail.type === "PARTIAL") {
      tempText = text.replace(/~.*?~/g, "") + `~${e.detail.text}~`;
      setIsFinal(false);
      setText(tempText);
    } else if (e.detail.type === "FINAL") {
      tempText = text.replace(/~.*?~/g, "") + " " + e.detail.text;
      setIsFinal(true);
      setText(tempText);
    } else if (e.detail.type === "STATUS") {
      if (e.detail.text === "LOADED") {
        setIsLoading(false);
      } else if (e.detail.text === "LOADING") {
        setIsLoading(true);
      }
    }
  };

  return {
    startVoice,
    stopVoice,
    isListening,
    isLoading,
    text,
    resetText,
    pauseVoice,
    isFinal,
  };
};

export default useVoice2Text;
