import styled from "styled-components";
import { CardWrapper } from "./Card";

const CardCoverWrapper = styled(CardWrapper)`
  margin-top: 28vh;
  font-family: "Helvetica", "Arial", sans-serif;
  color: #595757;
  font-weight: 500;
`;

const Title = styled.div`
  margin-top: 45px;
  font-size: 28px;
  font-weight: 800;
`;

const SubTitle = styled.div`
  margin: 5px 0;
`;

const Body = styled.div`
  margin: 0;
`;

const Footer = styled.div`
  margin-top: 45px;
  text-align: center;
`;

const FooterTitle = styled.div`
  margin-top: 5px;
  font-size: 20px;
  font-weight: 600;
`;

const FooterBody = styled.div`
  margin-top: 2px;
  text-align: center;
  font-size: 16px;
`;

const LastPage = () => {
  return (
    <CardCoverWrapper>
      <Title>THE END</Title>
      <SubTitle>Thank you so much for your purchase!</SubTitle>
      <Body>
        Please consider giving us a review. It would really help us out!
      </Body>
      <Footer>
        <FooterTitle>How can we improve?</FooterTitle>
        <FooterBody>
          Please send us an email at machilleshector@gmail.com
        </FooterBody>
      </Footer>
    </CardCoverWrapper>
  );
};

export default LastPage;
