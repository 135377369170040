import React from "react";
import styled from "styled-components";
import Replay from "./play-icon-rotate.svg";
import Play from "./play-solid-icon.svg";
import Stop from "./stop-icon-solid.svg";
// import posthog from "posthog-js";

import { PlayButton } from "./PlayButton";
import { PassageProperties } from "./data";
import useParsePassage from "./hooks/useParsePassage";

export const CardWrapper = styled.div`
  background: #eef3f7;
  display: flex;
  /* border: 1px solid blue; */
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  border-radius: 15px;
  padding: 20px 20px 0 20px;
  width: 90%;
  margin: auto;
  /* margin-top: 30vh; */
  margin-bottom: 75px;

  /* height: 300px; */ //********************* */
  height: 52vh;
  max-width: 600px;

  margin-top: 35vh;

  //iphone SE
  @media (max-width: 320px) {
    height: 247px;
  }
`;

export const Cover = styled.div`
  width: 100%;
  position: relative;
  border-radius: 15px;
  z-index: 5;
  margin-top: -190px;
  /* border: 1px solid green; */
  /* margin-top: -200px; */

  /* height: 350px; */

  height: 500px;
  /* margin-top: -290px; */
  margin-bottom: -90px;

  //iphone SE
  /* @media (min-height: 664px) and (min-width: 374px) {
    height: 500px;
  } */
`;

export const ImageWrapper = styled.div<{ $url: string }>`
  width: 100%;
  height: 90%;

  background-image: url(${(props) => props.$url});
  background-repeat: no-repeat;
  background-size: 110%;
  background-position: center;

  flex-shrink: 0;
  position: relative;
  z-index: 2;
  border-radius: 15px;

  /* box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32); */
  box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);

  &:after {
    content: "";
    background: inherit;
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
    display: block;
    z-index: 2;
    position: absolute;
    border-radius: 15px;
  }

  &:before {
    content: "";
    background: inherit;
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 40px 0px rgba(76, 70, 124, 0.5);
    display: block;
    z-index: 1;
    position: absolute;
    top: 30px;
    transform: scale(0.9);
    filter: blur(10px);
    opacity: 0.9;
    border-radius: 15px;
  }
`;

export const MediaControl = styled.div<{ show: boolean }>`
  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.6s linear;
  /* border: 1px solid red; */
`;

export const Word = styled.span<{
  active: boolean;
  willTurnPage: boolean;
}>`
  /* font-size: 28px; */
  font-size: 23px;
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  color: ${(props) =>
    props.willTurnPage ? "#049319" : props.active ? "red" : "#595757"};

  //iphone SE
  @media (max-width: 320px) {
    font-size: 23px;
  }
`;

// export const SoundWord = styled.span<{ amount: number }>`
//   /* display: block; */
//   /* width: 100%; */
//   font-family: "Helvetica", "Arial", sans-serif;
//   font-weight: bold;
//   font-size: 28px;
//   background: #7f7e7e;
//   -webkit-background-clip: text;
//   color: transparent;
//   background-image: ${(props) =>
//     "linear-gradient(90deg, #e750b7" +
//     props.amount +
//     "%, rgba( 0, 0, 0, 0 ) " +
//     props.amount +
//     "%)"};
// `;

export const Table = styled.div`
  width: 100%;
  mask-image: linear-gradient(transparent, black 30%, black 80%, black 80%);
  -webkit-mask-image: linear-gradient(
    transparent,
    black 30%,
    black 80%,
    black 80%
  );
  overflow-y: hidden;
  padding: 20px;
  box-sizing: content-box; /* So the width will be 100% + 17px */
  /* overflow-y: scroll; */
  /* height: 200px; */
  height: 350px;
  /* height: 32vh; */
  scroll-behavior: smooth;
  /* border: 1px solid red; */
  padding-top: 100px;
  /* margin-top: 20px; */
  /* padding-bottom: 80px; */
  /* margin-bottom: 10px; */
  z-index: 1;
  text-align: center;
  line-height: 2.5rem;
`;

export const Icon = styled.img<{ disabled: boolean }>`
  width: 20px;
  height: 20px;

  cursor: ${(props) => (props.disabled ? "mouse" : "pointer")};
`;

export const PlayBackdrop = styled.div<{ showBlur?: boolean }>`
  -o-backdrop-filter: ${(props) => (props.showBlur ? "blur(4.5px)" : null)};
  -moz-backdrop-filter: ${(props) => (props.showBlur ? "blur(4.5px)" : null)};
  -webkit-backdrop-filter: ${(props) =>
    props.showBlur ? "blur(4.5px)" : null};
  backdrop-filter: ${(props) => (props.showBlur ? "blur(4.5px)" : null)};

  border-radius: 15px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Player = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const CircleOfCompletionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
`;

export const CircleOfCompletion = styled.div<{ completed: boolean }>`
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: ${(props) => (props.completed ? "#9dd6a1" : "#ddddf4")};
`;

export const ProgressBar = styled.progress<{ $done: boolean; show: boolean }>`
  height: 30px;
  width: 75%;

  height: 8px;
  background-color: #d0d8e6;

  opacity: ${(props) => (props.show ? 1 : 0)};
  transition: opacity 0.6s linear;

  &::-webkit-progress-bar {
    background-color: #d0d8e6;
    border-radius: 10px;
  }

  &::-webkit-progress-value {
    background-color: ${(props) => (props.$done ? "#9dd6a1" : "#a3b3ce")};
    border-radius: 10px;
  }
`;

export const HorizontalLine = styled.hr`
  border: 1px solid #ccd0d4;
  opacity: 0.6;
`;

export const PageNumber = styled.span`
  /* margin-top: 15px; */
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: bold;
  font-size: 28px;
`;

export const Card: React.FC<{
  pageNumber: number;
  pageSounds: Howl[];
  currentActivePage: number;
  passage: PassageProperties;
  handleJumpToSlide(slideIndex: number): void;
  isListening: boolean;
  transcript: string;
  imageSrc: string;
  setCounter: React.Dispatch<React.SetStateAction<number>>;
  counter: number;
  indexPlaying: number;
  setIndexPlaying: React.Dispatch<React.SetStateAction<number>>;
  playedArray: Set<string>;
  setPlayedArray: React.Dispatch<React.SetStateAction<Set<string>>>;
}> = (props) => {
  const {
    pageNumber,
    pageSounds,
    passage,
    transcript,
    currentActivePage,
    isListening,
    handleJumpToSlide,
    imageSrc,
    setCounter,
    counter,
    indexPlaying,
    setIndexPlaying,
    playedArray,
    setPlayedArray,
  } = props;

  const [audioDuration, setAudioDuration] = React.useState(0);
  const [audioCurrentTime, setAudioCurrentTime] = React.useState(0);
  const [willTurnPage, setWillTurnPage] = React.useState(false);

  const limit = pageSounds.length;

  const {
    didItMatch,
    didMatchedLastWordRange,
    arrayPassage,
    activeWordIndex,
    setActiveWordIndex,
  } = useParsePassage(passage);

  const isSoundWordAtLast =
    arrayPassage.findIndex((word) => word === passage.audio) ===
    arrayPassage.length - 1;

  const handleOnClick = (index = counter, isSoundWordAtLast = false) => {
    if (index === limit) return;

    const sound = pageSounds[index];
    if (indexPlaying === index) {
      sound.stop();
      sound.pos(0);
      setIndexPlaying(-1);
      // posthog.capture("audio status", {
      //   status: "paused",
      // });
    } else {
      // posthog.capture("audio status", {
      //   status: "play",
      // });
      sound.pos(0);
      setAudioDuration(sound.duration());

      sound.play();
      setIndexPlaying(index);
      // setCounter(index);

      const isPlaying = () => {
        setAudioCurrentTime(sound.seek());
        if (sound.playing()) {
          setTimeout(isPlaying, 500); //adjust timeout to fit your needs
        }
      };

      isPlaying();

      sound.on("end", () => {
        setPlayedArray((prev) =>
          prev.add(`audio_tag_page${pageNumber}-${index}`)
        );
        setIndexPlaying(-1);
        // setCounter((prev) => (prev < limit ? prev + 1 : prev));
        if (playedArray.size === limit) {
          console.log("limit hit");
        }
        if (isSoundWordAtLast || willTurnPage) {
          handleJumpToSlide(currentActivePage + 1);
        }
      });
    }
  };

  const determineState = (
    index: number,
    audioPlaying: string | number | Function,
    audioDone: string | number | Function,
    audioStopped: string | number | Function
  ) => {
    // Audio is currently playing
    if (indexPlaying === index) {
      return audioPlaying;
    }
    // Audio is finished
    else if (playedArray.has(`audio_tag_page${pageNumber}-${index}`)) {
      return audioDone;
    }
    // Audio is stopped
    return audioStopped;
  };

  const handleFromMainButtonPress = () => {
    handleOnClick();
    // posthog.capture("button press", {
    //   button: "main button",
    // });
  };

  const handleFromTableButtonPress = (index: number) => {
    handleOnClick(index);
    // posthog.capture("button press", {
    //   button: "table play button",
    // });
  };

  React.useEffect(() => {
    if (currentActivePage === pageNumber) {
      const matched = didItMatch(transcript);
      const matchedLastWordRange = didMatchedLastWordRange(transcript);

      if (matched || matchedLastWordRange) {
        //Play sound if matched
        if (
          !!passage.audio &&
          indexPlaying === -1 &&
          arrayPassage[activeWordIndex] === passage.audio
        ) {
          if (isSoundWordAtLast) {
            setWillTurnPage(true);
          }
          handleOnClick(counter, isSoundWordAtLast);
        }
        // Move to the next word
        if (arrayPassage.length !== activeWordIndex + 1) {
          setActiveWordIndex(activeWordIndex + 1);
          document?.querySelector(".word-activated")?.scrollIntoView(true);
        }
        if (matchedLastWordRange && !isSoundWordAtLast) {
          setWillTurnPage(true);
        }
      }
      if (willTurnPage && indexPlaying === -1) {
        handleJumpToSlide(currentActivePage + 1);
      }
    }
  }, [transcript, indexPlaying, willTurnPage]);

  return (
    <CardWrapper id={`${pageNumber}`}>
      <Cover>
        <ImageWrapper $url={imageSrc}>
          <PlayBackdrop
            showBlur={
              indexPlaying !== -1 ||
              playedArray.has(`audio_tag_page${pageNumber}-${0}`)
            }
          >
            {
              <MediaControl
                show={
                  indexPlaying !== -1 ||
                  playedArray.has(`audio_tag_page${pageNumber}-${0}`)
                }
              >
                <PlayButton
                  onClick={handleFromMainButtonPress}
                  playing={indexPlaying === -1}
                  done={playedArray.size === limit}
                />
              </MediaControl>
            }
            <ProgressBar
              $done={playedArray.has(`audio_tag_page${pageNumber}-${0}`)}
              show={
                indexPlaying !== -1 ||
                playedArray.has(`audio_tag_page${pageNumber}-${0}`)
              }
              max={audioDuration}
              value={
                determineState(
                  indexPlaying,
                  audioCurrentTime,
                  audioDuration,
                  0
                ) as number
              }
            />
          </PlayBackdrop>
        </ImageWrapper>
      </Cover>
      <Table>
        {passage.words.split(" ").map((word, index) => {
          return (
            <Word
              key={index}
              className={
                index === activeWordIndex - 8 && isListening
                  ? "word-activated"
                  : "word-deactivated"
              }
              willTurnPage={willTurnPage}
              active={
                (index === activeWordIndex ||
                  (index < activeWordIndex + 3 && index >= activeWordIndex)) &&
                isListening
              }
            >
              {word}{" "}
            </Word>
          );
        })}
      </Table>
    </CardWrapper>
  );
};
