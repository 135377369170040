import React from "react";
import { PassageProperties } from "./data";
import {
  CardWrapper,
  Cover,
  ImageWrapper,
  PlayBackdrop,
  Table,
  Word,
  PageNumber,
} from "./Card";

export const DummyCard: React.FC<{
  pageNumber: number;
  pageSounds: Howl[];
  passage: PassageProperties;
  isListening: boolean;
  transcript: string;
  imageSrc: string;
  indexPlaying: number;
  playedArray: Set<string>;
}> = (props) => {
  const { pageNumber, passage, imageSrc } = props;

  return (
    <CardWrapper id={`${pageNumber}`}>
      <Cover>
        <ImageWrapper $url={imageSrc}>
          <PlayBackdrop />
        </ImageWrapper>
      </Cover>
      <Table>
        {passage.words.split(" ").map((word, index) => {
          return (
            <Word
              key={index}
              className={"word-deactivated"}
              willTurnPage={false}
              active={false}
            >
              {word}{" "}
            </Word>
          );
        })}
      </Table>
    </CardWrapper>
  );
};
