import React, { useState } from "react";
import { PassageProperties } from "../data";

const useParsePassage = (passage: PassageProperties) => {
  const arrayPassage = passage.words
    .toLocaleLowerCase()
    .replace(RegExp(/[^a-zA-Z\d\s:]/g), "")
    .split(" ");

  const [activeWordIndex, setActiveWordIndex] = useState(0);

  const goThroughRange = (
    rangeLimit: number,
    acceptableHitsWithinRange: number,
    normalizedTranscriptArray: string[]
  ) => {
    let hits = 0;
    for (
      let range = activeWordIndex;
      range < activeWordIndex + rangeLimit;
      range++
    ) {
      if (!arrayPassage[range]) break;

      if (
        normalizedTranscriptArray.findIndex(
          (word, index) => word[0] === arrayPassage[range][0]
        ) > -1
      ) {
        hits++;
      }

      if (hits >= acceptableHitsWithinRange) {
        return true;
      }
    }

    return false;
  };

  const didItMatch = (transcript: string) => {
    //Removes all the caps and non-alphanumerics
    const normalizedTranscriptArray = transcript
      .toLocaleLowerCase()
      .replace(RegExp(/[^a-zA-Z\d\s:]/g), "")
      .split(" ");

    return goThroughRange(4, 1, normalizedTranscriptArray);
  };

  const didMatchedLastWordRange = (transcript: string) => {
    //Removes all the caps and non-alphanumerics
    const normalizedTranscriptArray = transcript
      .toLocaleLowerCase()
      .replace(RegExp(/[^a-zA-Z\d\s:]/g), "")
      .split(" ");

    const lastPassageIndex = arrayPassage.length - 1;
    const rangeLimit = 2;
    if (activeWordIndex >= lastPassageIndex - rangeLimit) {
      return goThroughRange(rangeLimit, 1, normalizedTranscriptArray);
    }
    return false;
  };

  return {
    didItMatch,
    didMatchedLastWordRange,
    arrayPassage,
    activeWordIndex,
    setActiveWordIndex,
  };
};

export default useParsePassage;
