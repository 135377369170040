import React, { useState } from "react";
import { pagesAssets } from "./data";
// import posthog from "posthog-js";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from "./Card";
import useVoice2Text from "./hooks/useVoice2Text";
import { DummyCard } from "./DummyCard";
import { Covers } from "./Covers";
import LastPage from "./LastPage";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

/**
 * TODO:
 * - Change the custom button from play to pause [DONE]
 * - Need to add image to the pages (need to remove the text) [DONE]
 * - Custom Button to be on the bottom of the page [DONE]
 * - Custom Button changes color when completed [DONE]
 *
 * - Main button plays and goes to the next track [DONE]
 * - Swipe right goes to the next page [DONE]
 * - Option to select the next sound manually [DONE]
 *
 * - When track is done it has to vibrate the phone.
 */

const Sound: React.FC<{}> = () => {
  const [counter, setCounter] = useState(0);
  const [currentActivePage, setActiveCurrentPage] = useState(0);

  // -1 means no track is being played
  const [indexPlaying, setIndexPlaying] = React.useState(-1);
  const [playedArray, setPlayedArray] = React.useState<Set<string>>(new Set());

  const carouselRef = React.useRef<Carousel>(null);

  const {
    isListening: listenStatus,
    stopVoice,
    startVoice,
    text,
    isLoading,
    resetText,
    isFinal,
  } = useVoice2Text();

  const handleAfterSwipe = (nextSlide: number) => {
    let audio = pagesAssets[nextSlide - 1]?.sounds?.[counter];
    if (currentActivePage > nextSlide) {
      audio = pagesAssets[nextSlide + 1]?.sounds?.[counter];
    }

    if (audio && !!audio?.playing()) {
      audio.stop();
      audio.pos(0);
    }

    setActiveCurrentPage(nextSlide);
    setCounter(0);
    resetText();
    setIndexPlaying(-1);
    setPlayedArray(new Set());

    // posthog.capture("page swiped", {
    //   pageSwipedTo: nextSlide,
    // });
  };

  const handleOnClickVoice = () => {
    if (!listenStatus) {
      startVoice();
    } else {
      stopVoice();
    }
  };

  const handleJumpToSlide = (slideIndex: number) => {
    if (carouselRef.current && isFinal) {
      carouselRef.current.goToSlide(slideIndex);
    }
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        ref={carouselRef}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        beforeChange={(nextSlide) => handleAfterSwipe(nextSlide)}
      >
        {pagesAssets.map(({ passage, sounds, imageSrc }, index) => {
          //Target first card
          if (index === 0) {
            return (
              <Covers
                handleOnClickVoice={handleOnClickVoice}
                handleJumpToSlide={handleJumpToSlide}
                listenStatus={listenStatus}
                isLoading={isLoading}
                pageNumber={currentActivePage}
                imageSrc={imageSrc}
                passage={passage}
              />
            );
          }
          //Target last card
          else if (index === pagesAssets.length - 1) {
            return <LastPage />;
          } else if (currentActivePage === index)
            return (
              <Card
                key={index}
                passage={passage}
                handleJumpToSlide={handleJumpToSlide}
                isListening={listenStatus}
                currentActivePage={currentActivePage}
                transcript={text}
                pageSounds={sounds}
                imageSrc={imageSrc}
                pageNumber={index}
                counter={counter}
                setCounter={setCounter}
                indexPlaying={indexPlaying}
                setIndexPlaying={setIndexPlaying}
                playedArray={playedArray}
                setPlayedArray={setPlayedArray}
              />
            );
          else if (
            currentActivePage === index + 1 ||
            currentActivePage === index - 1
          )
            return (
              <DummyCard
                key={index}
                passage={passage}
                // handleJumpToSlide={handleJumpToSlide}
                isListening={listenStatus}
                // currentActivePage={currentActivePage}
                transcript={text}
                pageSounds={sounds}
                imageSrc={imageSrc}
                pageNumber={index}
                // counter={counter}
                // setCounter={setCounter}
                indexPlaying={-1}
                // setIndexPlaying={setIndexPlaying}
                playedArray={playedArray}
                // setPlayedArray={setPlayedArray}
              />
            );
          return <div />;
        })}
      </Carousel>
    </>
  );
};

export default Sound;
