import React from "react";
import MicroOn from "./micro-on.svg";
import MicroOff from "./micro-off.svg";

import { PassageProperties } from "./data";
import {
  CardWrapper,
  ImageWrapper,
  PlayBackdrop,
  Table,
  PageNumber,
  Cover,
  Card,
} from "./Card";
import styled from "styled-components";
import Spinner from "./Spinner";
import Checkmark from "./Checkmark";

const ImageCoverWrapper = styled(ImageWrapper)`
  /* background-size: contain; */
  background-position-y: 14%;
  background-size: 100%;
  /* background-size: contain; */
`;

const CheckmarkWrapper = styled.div`
  margin-bottom: -18px;
`;

const Words = styled.div`
  font-size: 18px;
  font-family: "Helvetica", "Arial", sans-serif;
  font-weight: bold;

  @media (max-width: 320px) {
    font-size: 14px;
  }
`;

const Icon = styled.img<{ listenStatus: boolean }>`
  width: 75px;
  height: 75px;

  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);

  background-color: ${(props) => (props.listenStatus ? "#f75757" : "#f9f9f9")};

  border: 3px solid ${(props) => (props.listenStatus ? "red" : "green")};
  padding: 15px;
  border-radius: 50%;

  cursor: pointer;
  margin-bottom: -10px;
`;

export const Covers: React.FC<{
  pageNumber: number;
  passage: PassageProperties;
  handleJumpToSlide(slideIndex: number): void;
  handleOnClickVoice(): void;
  listenStatus: boolean;
  isLoading: boolean;
  imageSrc: string;
}> = (props) => {
  const {
    pageNumber,
    passage,
    handleJumpToSlide,
    handleOnClickVoice,
    isLoading,
    listenStatus,
    imageSrc,
  } = props;

  return (
    <CardWrapper id={`${pageNumber}`}>
      <Cover>
        <ImageCoverWrapper $url={imageSrc}>
          <PlayBackdrop></PlayBackdrop>
        </ImageCoverWrapper>
      </Cover>

      <Table>
        <div>
          {isLoading ? (
            <Spinner />
          ) : listenStatus ? (
            <CheckmarkWrapper>
              <Checkmark />
            </CheckmarkWrapper>
          ) : (
            <Icon
              listenStatus={listenStatus}
              onClick={handleOnClickVoice}
              src={MicroOn}
            />
          )}
        </div>
        <Words>
          {isLoading
            ? ""
            : listenStatus
            ? "Swipe to next page."
            : passage.words}
        </Words>
      </Table>
    </CardWrapper>
  );
};
