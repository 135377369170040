import { Howl } from "howler";

export type PassageProperties = {
  words: string;
  audio?: string;
};

type PageAsset = {
  imageSrc: string;
  sounds: Howl[];
  passage: PassageProperties;
};

export const pagesAssets: PageAsset[] = [];
for (let slide = 0; slide < 36; slide++) {
  let sounds = [];
  let passage = { words: "" };
  try {
    const soundTemp = require(`./assets/slides/${slide}/${slide}.mp3`);
    const sound = new Howl({
      src: [soundTemp],
    });
    sounds.push(sound);
  } catch (e) {}
  try {
    passage = require(`./assets/slides/${slide}/${slide}.json`);
  } catch (e) {}
  pagesAssets.push({
    imageSrc: require(`./assets/slides/${slide}/${slide}.jpg`),
    passage,
    sounds,
  });
}
