import React from "react";
import styled from "styled-components";

const RADIUS = 0;
const circumf = `${3.1416 * RADIUS * 2}px`;
const checkLen = "75px";

const CheckmarkSvg = styled.svg`
  @keyframes outline {
    from {
      stroke-dasharray: 0, ${circumf};
    }
    to {
      stroke-dasharray: ${circumf}, ${circumf};
    }
  }

  & #outline {
    animation: 0.38s ease-in outline;
    transform: rotate(0deg);
    transform-origin: center;
    box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  }

  @keyframes circle {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
    }
  }

  & #white-circle {
    animation: 0.35s ease-in 0.35s forwards circle;
    transform: none;
    transform-origin: center;
    box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  }

  @keyframes check {
    from {
      stroke-dasharray: 0, ${checkLen};
    }
    to {
      stroke-dasharray: ${checkLen}, ${checkLen};
    }
  }

  & #check {
    animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
    stroke-dasharray: 0, ${checkLen};
  }

  @keyframes check-group {
    from {
      transform: scale(1);
    }

    50% {
      transform: scale(1.09);
    }

    to {
      transform: scale(1);
    }
  }

  & #check-group {
    animation: 0.32s ease-in-out 1.03s check-group;
    transform-origin: center;
  }
`;

const Checkmark = () => {
  return (
    <CheckmarkSvg
      width="85px"
      height="85px"
      viewBox="0 0 133 133"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="check-group"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <circle
          id="filled-circle"
          fill="#78B348"
          cx="66.5"
          cy="66.5"
          r="54.5"
        ></circle>
        <circle
          id="white-circle"
          fill="#FFFFFF"
          cx="66.5"
          cy="66.5"
          r="55.5"
        ></circle>
        <circle
          id="outline"
          stroke="#78B348"
          stroke-width="4"
          cx="66.5"
          cy="66.5"
          r="54.5"
        ></circle>
        <polyline
          id="check"
          stroke="#FFFFFF"
          stroke-width="4"
          points="41 70 56 85 92 49"
        ></polyline>
      </g>
    </CheckmarkSvg>
  );
};

export default Checkmark;
